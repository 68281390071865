import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import Public from './Public';
import Sources from './pages/Sources';
import Home from './pages/Home';
import Destinations from './pages/Destinations';
import Transformations from './pages/Transformations';
import NewSource from './pages/Sources/NewSource';
import SourceDetails from './pages/Sources/SourceDetails';
import Pipelines from './pages/Pipelines';
import NewDestination from './pages/Destinations/NewDestination';
import Loading from './components/Loading';
import RedirectRoute from './components/RedirectRoute';
import DestinationDetails from './pages/Destinations/DestinationDetails';
import NewPipeline from './pages/Pipelines/NewPipeline';
import PipelineDetails from './pages/Pipelines/PipelineDetails';
import NewTransformation from './pages/Transformations/NewTransformation';
import TransformationDetails from './pages/Transformations/TransformationDetails';
import Users from './pages/Users';
import NewUser from './pages/Users/NewUser';
import UserDetails from './pages/Users/UserDetails';
import Apps from './pages/Apps';
import UnknownError from './pages/Public/UnknownError';
import Settings from './pages/Settings';
import Configurations from './pages/Configuration';
import NewConfiguration from './pages/Configuration/NewConfiguration';
import ConfigurationDetails from './pages/Configuration/ConfigurationDetails';
import Decisions from './pages/Decisions';
import NewDecision from './pages/Decisions/NewDecision';
import DecisionDetails from './pages/Decisions/DecisionDetails';
import Functions from './pages/Functions';
import NewFunction from './pages/Functions/NewFunction';
import FunctionDetails from './pages/Functions/FunctionDetails';
import GlobalVariables from './pages/GlobalVariable';
import NewGlobalVariable from './pages/GlobalVariable/NewGlobalVariable';
import GlobalVariableDetails from './pages/GlobalVariable/GlobalVariableDetails';
import AutoCompleteEditor from './pages/POCEditor';
import ChatComponent from './pages/POCEditor/ChatComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppRoutes = () => {
  return <BrowserRouter basename="/app">
    <Suspense fallback={<div><Loading /></div>}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="home" element={<Home />} />
          <Route path="autocomplete" element={<AutoCompleteEditor />} />
          <Route path="chat" element={<ChatComponent />} />
          <Route path="sources">
            <Route path="" element={<Sources />} />
            <Route path="new" element={<NewSource />} />
            <Route path=":id" element={<SourceDetails />} />
            <Route path=":id/:tab" element={<SourceDetails />} />
          </Route>
          <Route path="destinations">
            <Route path="" element={<Destinations />} />
            <Route path="new" element={<NewDestination />} />
            <Route path=":id" element={<DestinationDetails />} />
            <Route path=":id/:tab" element={<DestinationDetails />} />
          </Route>
          <Route path="pipelines" >
            <Route path="" element={<Pipelines />} />
            <Route path="new" element={<NewPipeline />} />
            <Route path=":id" element={<PipelineDetails />} />
            <Route path=":id/:tab" element={<PipelineDetails />} />
          </Route>
          <Route path="transformations" >
            <Route path="" element={<Transformations />} />
            <Route path="new" element={<NewTransformation />} />
            <Route path=":id" element={<TransformationDetails />} />
            <Route path=":id/:tab" element={<TransformationDetails />} />
          </Route>
          <Route path="users" >
            <Route path="" element={<Users />} />
            <Route path="new" element={<NewUser />} />
            <Route path=":id" element={<UserDetails />} />
          </Route>
          <Route path="apps">
            <Route path="" element={<Apps />} />
          </Route>
          <Route path="configurations">
            <Route path="" element={<Configurations />} />
            <Route path="new" element={<NewConfiguration />} />
            <Route path=":id" element={<ConfigurationDetails />} />
            <Route path=":id/:tab" element={<ConfigurationDetails />} />
          </Route>
          <Route path="decisions" >
            <Route path="" element={<Decisions />} />
            <Route path="new" element={<NewDecision />} />
            <Route path=":id" element={<DecisionDetails />} />
            <Route path=":id/:tab" element={<TransformationDetails />} />
          </Route>
          <Route path="functions">
            <Route path="" element={<Functions />} />
            <Route path="new" element={<NewFunction />} />
            <Route path=":id" element={<FunctionDetails />} />
            <Route path=":id/:tab" element={<FunctionDetails />} />
          </Route>
          <Route path="settings">
            <Route path="" element={<Settings />} />
            <Route path=":tab" element={<Settings />} />
          </Route>
          <Route path="global-variables">
            <Route path="" element={<GlobalVariables />} />
            <Route path="new" element={<NewGlobalVariable />} />
            <Route path=":id" element={<GlobalVariableDetails />} />
            <Route path=":id/:tab" element={<GlobalVariableDetails />} />
          </Route>
          <Route exact path="" element={<Navigate to="/home" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
}

const PublicRoutes = () => {
  return <BrowserRouter basename=''>
    <Suspense fallback={<div><Loading /></div>}>
      <Routes>
        <Route path="/" element={<Public />}>
          <Route path="unknownError" element={<UnknownError />}> </Route>
          <Route index element={<RedirectRoute to={'/app'} />} />
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>
}

root.render(
  <>
    <AppRoutes />
    <PublicRoutes />
  </>

);
