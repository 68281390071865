import { IconCheck, IconExclamationCircle, IconLoader3, IconX } from '@tabler/icons-react';
import { Fragment, useState, useEffect } from "react";
import useNotifications from '../../../hooks/useNotifications';
import { renderErrors } from "../../../common/formHelpers";
import keys from 'lodash/keys';
import get from 'lodash/get';
import axios from 'axios';
import ConfigBuilder from '../../../components/ConfigBuilder';
import filter from 'lodash/filter';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../components/ConfirmModal';
import Modal from '../../../components/Modal';
import cloneDeep from 'lodash/cloneDeep';
import { generateWorkspacePath } from '../../../common/urlHelpers';
import ScriptLanguageSelector from '../../../components/ScriptLanguageSelector';
import { mainScriptDestinationTemplate, modScriptTemplate, pythonMainScriptTransformationTemplate } from '../../../common/scripts';
import TestScriptRun from '../../../common/TestScriptRun';

const scriptTemplate = `module.exports = {
    setup: function(config) { /* optional */
        //setup connection
    },
    execute: function(event, ctx, config) { /* required */
        //send to destination.
    },
    teardown: function(config) { /* optional */
        //teardown connection
    }
}`;

const DestinationDetailsCard = (props) => {
    const { data: { id, name, script, active, config, language, golang, python, buildStatus, signatureVersion, errorMessage }, onUpdate = () => { }, readOnly = false } = props;
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [editMode, setEditMode] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [changes, setChanges] = useState({});
    const [executing, setExecuting] = useState(false);
    const [configChanges, setConfigChanges] = useState(config != undefined ? cloneDeep(config) : []);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [expandEditor, setExpandEditor] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(language);
    const [event, setEvent] = useState(null);
    const [version, setVersion] = useState(signatureVersion);

    const workspacePath = generateWorkspacePath();

    useEffect(() => {
        setChanges(current => ({
            ...current,
            config: filter(configChanges, item => item.key)
        }));
    }, [configChanges]);

    const saveChanges = (e) => {
        e.preventDefault();
        let payload = props.data;
        payload.language = selectedLanguage;

        if (payload.config === undefined) {
            payload.config = [];
        }
        keys(payload).forEach(key => {
            if (changes[key]) {
                payload[key] = changes[key];
            }
        });
        switch (selectedLanguage) {
            case 'golang':
                if (payload.golang === undefined) {
                    payload.golang = { main: "", mod: "" };
                }
                keys(payload.golang).forEach(key => {
                    if (changes[key]) {
                        payload.golang[key] = changes[key];
                    }
                });
                delete payload.script;
                delete payload.python;
                break;
            case 'python':
                if (payload.python === undefined) {
                    payload.python = { main: "", requirements: "" }
                }
                keys(payload.python).forEach(key => {
                    if (changes[key]) {
                        payload.python[key] = changes[key];
                    }
                });
                delete payload.script;
                delete payload.golang;
                break;
            default:
                if (changes.script) {
                    payload.script = changes.script;
                }
                delete payload.python;
                delete payload.golang;
                break;
        }
        if (keys(payload).length === 0) {
            setEditMode(false);
            return;
        }
        payload.signatureVersion = version;

        setExecuting(true);
        axios.put(`${workspacePath}/destinations/${id}`, payload).then(response => {
            onUpdate(response.data);
            setEditMode(false);
            let message, type
            if (payload.golang) {
                message = 'Destination has been updated and build is in progress.'
                type = 'warning'
            } else {
                message = 'Destination has been created.';
                type = 'success'
            }
            addNotification({
                message: message,
                type: type
            });

        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while updating Destination'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        });
    };

    const deleteDestination = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.delete(`${workspacePath}/destinations/${id}`).then(response => {
            addNotification({
                message: 'Destination has been deleted',
                type: 'success'
            });
            navigate('/destinations');
        }).catch(err => {
            let message, type
            if (err?.response?.status === 409) {
                console.log(err)
                message = get(err, 'response.data.error.message', "This destination is used by pipelines.") + " Remove the destination from pipeline to delete."
                type = 'error'
            } else {
                message = get(err, 'response.data.message', 'An error occurred while deleting Destination')
                type = 'error'
            }
            addNotification({ message: message, type: type });

            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        });
    };

    const cancelChanges = (e) => {
        e.preventDefault();
        setChanges({});
        setConfigChanges(config ? cloneDeep(config) : []);
        setEditMode(false);
    };

    const exportAsApp = (e) => {
        e.preventDefault();
        setExecuting(true);
        axios.get(`${workspacePath}/destinations/${id}/export`).then(response => {
            addNotification({
                message: 'Destination has been exported',
                type: 'success'
            });
            navigate('/destinations');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while deleting Destination'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
            setShowDeleteConfirmation(false);
        });
    };

    return (
        <div className="card bg-base-100 mb-4">
            <div className="card-body p-4">
                <div className="text-lg font-bold bg-base-200 p-2 rounded-md">Destination Details</div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Destination Name</span>
                    </label>
                    {editMode ? (
                        <Fragment>
                            <input
                                type="text"
                                placeholder="Provide a name for the Destination"
                                defaultValue={name}
                                className="input input-bordered w-full"
                                onChange={e => setChanges(current => ({
                                    ...current,
                                    name: e.target.value
                                }))}
                            />
                            {renderErrors(error, 'name')}
                        </Fragment>
                    ) : (
                        <div className="p-1 text-lg">{name}</div>
                    )}
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text">Configuration</span>
                    </label>
                    <ConfigBuilder config={configChanges} setConfig={setConfigChanges} readOnly={!editMode} />
                    {renderErrors(error, 'config')}
                </div>
                {!readOnly && (
                    <ScriptLanguageSelector
                        type="DESTINATION"
                        script={changes.script || script || scriptTemplate}
                        setScript={(value) => setChanges(current => ({
                            ...current,
                            script: value
                        }))}
                        mainScript={(changes && changes.main) || (golang && golang.main) || mainScriptDestinationTemplate}
                        setMainScript={(value) => setChanges(current => ({
                            ...current,
                            main: value,
                        }))}
                        modScript={(changes && changes.mod) ||(golang && golang.mod) || modScriptTemplate}
                        setModScript={(value) => setChanges(current => ({
                            ...current,
                            mod: value,
                        }))}
                        pythonMainScript={(changes && changes.main) || (python && python.main) || pythonMainScriptTransformationTemplate}
                        setPythonMainScript={(value) => setChanges(current => ({
                            ...current,
                            main: value,
                        }))}
                        requirements={(changes && changes.requirements) || (python && python.requirements)}
                        setRequirements={(value) => setChanges(current => ({
                            ...current,
                            requirements: value,
                        }))}
                        expandEditor={expandEditor}
                        setExpandEditor={setExpandEditor}
                        error={error}
                        addNotification={addNotification}
                        workspacePath={workspacePath}
                        editMode={editMode}
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={setSelectedLanguage}
                        version={version}
                        setVersion={setVersion}
                    />

                )}
                <div className="flex">
                    <div className="form-control w-6/12">
                        <label className="label">
                            <span className="label-text">Active</span>
                        </label>
                        {editMode ? (
                            <Fragment>
                                <input
                                    type="checkbox"
                                    className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`}
                                    defaultChecked={active}
                                    onChange={e => setChanges(current => ({
                                        ...current,
                                        active: e.target.checked
                                    }))}
                                />
                                {renderErrors(error, 'active')}
                            </Fragment>
                        ) : (
                            <div className="p-1">{active ? <IconCheck className="text-success" size={24} /> : <IconX className="text-error" size={24} />}</div>
                        )}
                    </div>
                    <div>
                        <label className="label">
                            <span className="label-text">Build Status</span>
                        </label>
                        <div className="p-1">

                            {
                                buildStatus === 'failed' && <div className='tooltip' data-tip="Build Failed" >
                                    <IconExclamationCircle className="text-error" />
                                </div>
                            }
                            {
                                buildStatus === 'success' && <div className='tooltip' data-tip="Build Success" >
                                    <IconCheck className="text-success" />
                                </div>
                            }
                            {
                                buildStatus === 'pending' && <div className='tooltip' data-tip="Build in Progress" >
                                    <IconLoader3 className="text-warning" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div>
                    {
                        buildStatus === 'failed' && <div className="mockup-code before:content-none">
                            {errorMessage.split('\n').map((l, i) => (
                                <pre data-prefix={i + 1} className="text-error"><code>{l}</code></pre>)
                            )}
                        </div>
                    }
                </div>
                {!readOnly && (
                    <div className="card-actions mt-2 justify-between">
                        <div>{editMode && <button className="btn btn-error" type="button" disabled={executing} onClick={() => setShowDeleteConfirmation(true)}>Delete</button>}</div>
                        <div className="flex justify-end">
                            {!editMode && <a className="btn btn-info mx-2" target="_blank" rel="noreferrer" onClick={exportAsApp}>Export as App</a>}
                            {!editMode && <button
                                className="btn btn-secondary mx-2"
                                onClick={() =>
                                    setEvent({ id: id, body: {}, type: "destinations" })
                                }
                            >
                                Run Script
                            </button>}
                            {!editMode && <button className="btn" onClick={() => setEditMode(true)}>Edit Destination</button>}
                            {editMode && <button className="btn mr-2" onClick={cancelChanges}>Cancel</button>}
                            {editMode && <button className="btn btn-primary" disabled={executing} onClick={saveChanges}>Save Changes</button>}
                        </div>
                    </div>
                )}
                {showDeleteConfirmation && <ConfirmModal title="Delete Destination" message="Are you sure you want to delete this Destination?" onConfirm={deleteDestination} onCancel={() => setShowDeleteConfirmation(false)} />}
                {expandEditor && (
                    <Modal large={true} title={editMode ? 'Script' : 'Script (Read-only)'} onCancel={() => setExpandEditor(false)}>
                        <>
                            <div className="px-6 pb-4">
                                <ScriptLanguageSelector
                                    type="DESTINATION"
                                    script={changes.script || script}
                                    setScript={value => setChanges(current => ({ ...current, script: value }))}
                                    mainScript={(changes && changes.main) || (golang && golang.main) || mainScriptDestinationTemplate}
                                    setMainScript={(value) => setChanges(current => ({
                                        ...current,
                                        main: value,
                                    }))}
                                    modScript={(changes && changes.mod) ||(golang && golang.mod) || modScriptTemplate}
                                    setModScript={(value) => setChanges(current => ({
                                        ...current,
                                        mod: value,
                                    }))}
                                    pythonMainScript={(changes && changes.main) || (python && python.main) || pythonMainScriptTransformationTemplate}
                                    setPythonMainScript={(value) => setChanges(current => ({
                                        ...current,
                                        main: value,
                                    }))}
                                    requirements={(changes && changes.requirements) || (python && python.requirements)}
                                    setRequirements={(value) => setChanges(current => ({
                                        ...current,
                                        requirements: value,
                                    }))}
                                    expandEditor={expandEditor}
                                    setExpandEditor={setExpandEditor}
                                    error={error}
                                    addNotification={addNotification}
                                    workspacePath={workspacePath}
                                    editMode={editMode}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedLanguage={setSelectedLanguage}
                                    version={version}
                                    setVersion={setVersion}
                                />
                            </div>
                            <div className="bg-base-200 px-4 py-3 justify-between sm:px-6 flex">
                                <button disabled={editMode} className="btn btn-primary mr-2" onClick={() => setEditMode(true)}>Edit</button>
                                <button className="btn" onClick={() => setExpandEditor(false)}>Collapse</button>
                            </div>
                        </>
                    </Modal>
                )}
            </div>
            {event && <Modal title={"Run Script of " + name} onCancel={() => setEvent(null)}>
                <TestScriptRun event={event} onCancel={() => setEvent(null)} onSendEvent={() => { }} />
            </Modal>}
        </div>
    );
};

export default DestinationDetailsCard;
