import React, { useState } from "react";
import { fetchGPTResponse } from "./openAiService";
import { IconSend } from "@tabler/icons-react";
import MonacoEditor from "@monaco-editor/react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm"; // Enables GitHub-style Markdown (tables, strikethrough, etc.)

const ChatComponent = () => {
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);

    const handleSend = async () => {
        if (!input.trim()) return;

        const userMessage = { role: "user", content: input };
        setMessages([...messages, userMessage]);

        const botResponse = await fetchGPTResponse(input);
        setMessages([...messages, userMessage, { role: "assistant", content: botResponse }]);

        setInput("");
    };

    return (
        <div className="w-full max-w-lg mx-auto p-4 bg-white shadow-lg rounded-xl">
            <h2 className="text-xl font-semibold text-center mb-4">Chat with GPT</h2>
            <h3 className="text-l font-semibold text-center mb-4">Set your token in the local storage under openai_token to start chatting</h3>

            <div className="h-80 overflow-y-auto p-2 border-b">
                {messages.map((msg, index) => (
                    <div key={index} className={`chat ${msg.role === "user" ? "chat-end" : "chat-start"}`}>
                        <div className={`chat-bubble ${msg.role === "user" ? "bg-blue-500 text-white" : "bg-gray-200 text-black"}`}>
                            <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || "");
                                        return !inline && match ? (
                                            <SyntaxHighlighter style={vscDarkPlus} language={match[1]} PreTag="div" {...props}>
                                                {String(children).replace(/\n$/, "")}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className="bg-gray-200 text-red-500 p-1 rounded" {...props}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            >
                                {msg.content}
                            </ReactMarkdown>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-2 flex items-center">
                <MonacoEditor
                    height="100px"
                    defaultLanguage="plaintext"
                    value={input}
                    onChange={(value) => setInput(value)}
                    options={{ minimap: { enabled: false }, fontSize: 14, theme: "vs-dark" }}
                />
                <button className="btn btn-primary ml-2" onClick={handleSend}>
                    <IconSend size={20} />
                </button>
            </div>
        </div>
    );
};

export default ChatComponent;
