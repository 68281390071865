import { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/Nav/PageTitle";
import React from "react";
import MonacoEditor from '@monaco-editor/react';
import {
    registerCompletion
} from 'monacopilot';

const AutoCompleteEditor = () => {
    const [editor, setEditor] = useState(null);
    const [monaco, setMonaco] = useState(null);

    useEffect(() => {
        if (!monaco || !editor) return;

        const completion = registerCompletion(monaco, editor, {
            endpoint: 'https://dev.cnips.eu/faas-srv/function/execute/c59703db-de7f-4d20-afd3-e5ae7682436d?key=jZKcDtCJM8VxF1Hx',
            language: 'javascript',
        });

        return () => {
            completion.deregister();
        };
    }, [monaco, editor]);


    return <Fragment>
        <MonacoEditor
            language="javascript"
            height={'100vh'}
            width={'100%'}
            theme='vs-dark'
            onMount={(editor, monaco) => {
                setEditor(editor);
                setMonaco(monaco);
            }}
        />
    </Fragment>
}

export default AutoCompleteEditor;
