import axios from "axios";

export const fetchGPTResponse = async (prompt) => {
  try {
    let token = localStorage.getItem("openai_token");

    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4",
        messages: [{ role: "user", content: prompt }],
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error("Error fetching GPT response:", error);
    return "Something went wrong!";
  }
};
