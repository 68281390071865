import React, { useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import Editor, { useMonaco } from '@monaco-editor/react';
import { IconArrowsMaximize, IconBraces, IconBug } from "@tabler/icons-react";
import axios from 'axios';
import { renderErrors } from '../../common/formHelpers';
import Tabs from '../Tabs';
import get from 'lodash/get';

const scriptTabsGo = [{ 'key': 'main', label: 'main.go' }, { 'key': 'mod', label: 'go.mod' }];
const scriptTabsPython = [{ 'key': 'main', label: 'main.py' }, { 'key': 'requirements', label: 'requirements.txt' }];

const ScriptLanguageSelector = ({
    type,
    script,
    setScript,
    mainScript,
    pythonMainScript,
    setPythonMainScript,
    setMainScript,
    modScript,
    setModScript,
    expandEditor,
    setExpandEditor,
    error,
    addNotification,
    editMode,
    selectedLanguage,
    setSelectedLanguage,
    version,
    setVersion,
    requirements,
    setRequirements
}) => {
    const editorRef = useRef(null);
    const monaco = useMonaco();
    const [templates, setTemplates] = useState(null);
    const [jsTemplates, setjsTemplates] = useState(null);
    const [goTemplates, setGOTemplates] = useState(null);
    const [pythonTemplates, setPythonTemplates] = useState(null);
    var { scriptTab } = useParams();
    const [goFileDisplay, setGoFileDisplay] = useState("main");
    const [loadingActions, setLoadingActions] = useState({ pythonErrors: false, pyFormattingCode: false, goFormattingCode: false });

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e.target.value);
    };
    const pythonErrorCheckStart = () => setLoadingActions({ ...loadingActions, pythonErrors: true });
    const pythonErrorCheckEnd = () => setLoadingActions({ ...loadingActions, pythonErrors: false });
    const pyFormattingCodeStart = () => setLoadingActions({ ...loadingActions, pyFormattingCode: true });
    const pyFormattingCodeEnd = () => setLoadingActions({ ...loadingActions, pyFormattingCode: false });
    const goFormattingCodeStart = () => setLoadingActions({ ...loadingActions, goFormattingCode: true });
    const goFormattingCodeEnd = () => setLoadingActions({ ...loadingActions, goFormattingCode: false });

    const pythonFormatterUrl = "/mgmt-srv/formatter/python"
    const goFormatterUrl = "/mgmt-srv/formatter/go"
    const pythonErrorFormatterUrl = "/mgmt-srv/formatter/python/errors"


    const handleVersionChange = (e) => {
        const template = templates.list.find(item => item.id === e.target.value);
        setMainScript(template.golang.main);
        setModScript(template.golang.mod);
        setVersion(e.target.value);
    };

    const handleTemplateVersionChange = (e) => {
        if (selectedLanguage === "javascript") {
            const template = jsTemplates.list.find(item => item.version === e.target.value);
            setScript(template.script);
            setVersion(e.target.value);
        } else if (selectedLanguage === "golang") {
            const template = goTemplates.list.find(item => item.version === e.target.value);
            setMainScript(template.golang.main);
            setModScript(template.golang.mod);
            setVersion(e.target.value);
        } else if (selectedLanguage === "python") {
            const template = pythonTemplates.list.find(item => item.version === e.target.value);
            setPythonMainScript(template.python.main);
            setRequirements(template.python.requirements);
            setVersion(e.target.value);
        }
    }

    const onScriptTabChange = (tab) => {
        scriptTab = tab
        setGoFileDisplay(tab)
    }

    const handleEditorWillMount = (monaco) => {
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            target: monaco.languages.typescript.ScriptTarget.ES6,
            allowNonTsExtensions: true,
            allowJs: true
        });

        // Python Formatter
        monaco.languages.registerDocumentFormattingEditProvider("python", {
            async provideDocumentFormattingEdits(model, options, token) {
                pyFormattingCodeStart()
                try {
                    const response = await axios.post(pythonFormatterUrl, { code: model.getValue() })
                    if (response.data && response.data.code) {
                        return [{ range: model.getFullModelRange(), text: response.data.code, },];
                    } else { console.error("Invalid response format from the server"); return []; }
                } catch (error) { console.error("Error formatting code:", error); return []; }
                finally { pyFormattingCodeEnd() }
            }
        })

        // Go formatter
        monaco.languages.registerDocumentFormattingEditProvider("go", {
            async provideDocumentFormattingEdits(model, options, token) {
                goFormattingCodeStart()
                try {
                    const response = await axios.post(goFormatterUrl, { code: model.getValue() })
                    if (response.data && response.data.code) {
                        return [{ range: model.getFullModelRange(), text: response.data.code, }]
                    } else { console.error("Invalid response format from the server"); return []; }
                }
                catch (error) { console.error("Error formatting code:", error); return []; }
                finally { goFormattingCodeEnd() }
            }
        })

        if (type === "faas" && templates === null) {
            axios.get(`/mgmt-srv/functionTemplates`).then(response => {
                setTemplates(response.data);
                if (response.data.list && response.data.list.length > 0) {
                    if (!version) {
                        setVersion(response.data.list[0].id);
                        setMainScript(response.data.list[0].golang.main);
                        setModScript(response.data.list[0].golang.mod);
                    }
                }
            }).catch(err => {
                addNotification({
                    message: get(err, 'response.data.message', 'An error occurred while fetching API data 1'),
                    type: 'error'
                });
            });
        }
        if (type !== "faas") {
            if (selectedLanguage === "javascript" && jsTemplates === null || selectedLanguage === "golang" && goTemplates === null || selectedLanguage === "python" && pythonTemplates === null) {
                axios.get(`/mgmt-srv/templates?scriptType=${type}&language=${selectedLanguage}`).then(response => {
                    if (response.data.list && response.data.list.length > 0) {
                        if (selectedLanguage === "javascript") {
                            setjsTemplates(response.data);
                        } else if (selectedLanguage === "golang") {
                            setGOTemplates(response.data);
                        } else if (selectedLanguage === "python") {
                            setPythonTemplates(response.data);
                        }
                        if (!version) {
                            if (response.data.list[0].script) {
                                setScript(response.data.list[0].script);
                            }
                            if (response.data.list[0].golang) {
                                setMainScript(response.data.list[0].golang.main);
                                setModScript(response.data.list[0].golang.mod);
                            }
                            if (response.data.list[0].python) {
                                setPythonMainScript(response.data.list[0].python.main);
                                setRequirements(response.data.list[0].python.requirements);
                            }
                            setVersion(response.data.list[0].version);
                        }
                    }
                }).catch(err => {
                    addNotification({
                        message: get(err, 'response.data.message', 'An error occurred while fetching API data 2'),
                        type: 'error'
                    });
                });
            }
        }
        monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);
    };

    const pythonCheckErrors = async (code) => {
        pythonErrorCheckStart()
        try {
            const response = await axios.post(pythonErrorFormatterUrl, { code: pythonMainScript });
            displayErrors(response.data);
        } catch (error) {
            console.error('Error checking code:', error);
        } finally {
            pythonErrorCheckEnd()
        }
    };
    const displayErrors = (errors) => {

        const markers = errors.map((error) => ({
            startLineNumber: error.location.row,
            startColumn: error.location.column,
            endLineNumber: error.location.end_row,
            endColumn: error.location.end_column,
            message: error.message,
            severity: monaco.MarkerSeverity.Error,
        }));
        // Set markers in Monaco editor
        addNotification({ message: `Found ${markers.length} error${markers.length > 1 ? 's' : ''}`, type: 'warning' })
        monaco.editor.setModelMarkers(editorRef.current.getModel(), "ruff", markers)
    };

    const formatDocument = () => {
        editorRef.current.trigger("anyString", "editor.action.formatDocument");
    }

    const handleEditorDidMount = (editor, monaco) => {
        editor.addCommand(
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyF,
            () => {
                editor.trigger("anyString", "editor.action.formatDocument");
            }
        );
        editorRef.current = editor;
        // monacoRef.current = monaco;
    };

    const handleEditorChange = (value) => {
        setScript(value);
    };

    const handleModEditorChange = (value) => {
        setModScript(value);
    };


    const handlePythonMainEditorChange = (value) => {
        setPythonMainScript(value);
    };
    const handlePythonRequirementsEditorChange = (value) => {
        setRequirements(value);
    };

    return (
        <div>
            <div className="form-control w-full">
                <label className="label">
                    <span className="label-text">Select Scripting Language</span>
                </label>
                <div className="input-group">
                    <select
                        className="input input-bordered w-full"
                        disabled={!editMode}
                        value={selectedLanguage}
                        onChange={(e) => { setSelectedLanguage(e.target.value); setVersion(null) }}
                    >
                        <option value="">Select Language</option>
                        <option value="javascript">Javascript</option>
                        <option value="golang">Golang</option>
                        <option value="python">Python</option>
                    </select>
                </div>
                {renderErrors(error, 'language')}
            </div>

            {!expandEditor && type && type === "faas" && selectedLanguage === "golang" && (
                <>
                    <label className="label">
                        <span className="label-text">Select Template Version</span>
                    </label>
                    <div className="input-group">
                        <select
                            className="input input-bordered w-full"
                            value={version || ""}
                            onChange={handleVersionChange}
                            disabled={!editMode}
                        >
                            <option value="" disabled>Select a template</option>
                            {templates && templates.list.map((template) => (
                                <option key={template.id} value={template.id}>
                                    {template.name} - {template.version}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}
            {!expandEditor && type && type !== "faas" && (
                <>
                    <label className="label">
                        <span className="label-text">Select Template Version</span>
                    </label>
                    <div className="input-group">
                        <select
                            className="input input-bordered w-full"
                            value={version || ""}
                            onChange={handleTemplateVersionChange}
                            disabled={!editMode}
                        >
                            <option value="" disabled>Select a template</option>
                            {selectedLanguage === "javascript" && jsTemplates && jsTemplates.list.map((template) => (
                                <option key={template.version} value={template.version}>
                                    {template.name} - {template.version}
                                </option>
                            ))}
                            {selectedLanguage === "golang" && goTemplates && goTemplates.list.map((template) => (
                                <option key={template.version} value={template.version}>
                                    {template.name} - {template.version}
                                </option>
                            ))}
                            {selectedLanguage === "python" && pythonTemplates && pythonTemplates.list.map((template) => (
                                <option key={template.version} value={template.version}>
                                    {template.name} - {template.version}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}

            {selectedLanguage === 'python' && (
                <div className="form-control w-full">
                    <div className="flex justify-between items-center">
                        <label className="label">
                            <span className="label-text">Script</span>
                        </label>
                        <div>
                            {editMode && <>
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        pythonCheckErrors()
                                    }}
                                    className="btn btn-sm btn-ghost tooltip"
                                    data-tip="Check for errors"
                                >
                                    {loadingActions.pythonErrors ?
                                        <span className="loading loading-ring loading-xs"></span>
                                        :
                                        <IconBug size={16} />
                                    }
                                </button>
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        formatDocument()
                                    }}
                                    className="btn btn-sm btn-ghost tooltip"
                                    data-tip="Format document<br>(Ctrl + F)"
                                >
                                    {
                                        loadingActions.pyFormattingCode ?
                                            <span className="loading loading-ring loading-xs"></span>
                                            :
                                            <IconBraces size={16} />
                                    }

                                </button>
                            </>}
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setExpandEditor(current => !current);
                                }}
                                className="btn btn-sm btn-ghost"
                            >
                                <IconArrowsMaximize size={16} />
                            </button>
                        </div>
                    </div>
                    <Tabs onClick={(e) => {
                        e.preventDefault();
                    }} tabs={scriptTabsPython} activeTab={goFileDisplay} onTabChange={onScriptTabChange} />
                    {goFileDisplay === "main" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="python"
                        value={pythonMainScript}
                        onChange={handlePythonMainEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {goFileDisplay === "requirements" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="txt"
                        value={requirements}
                        onChange={handlePythonRequirementsEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {renderErrors(error, 'pythonMainScript')}
                    {renderErrors(error, 'requirements')}
                </div>
            )}

            {(selectedLanguage === 'golang' || selectedLanguage === 'Go' || selectedLanguage === 'go') && (
                <div className="form-control w-full">
                    <div className="flex justify-between items-center">
                        <label className="label">
                            <span className="label-text">Script</span>
                        </label>
                        <div>
                            {editMode && <>
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        formatDocument()
                                    }}
                                    className="btn btn-sm btn-ghost tooltip"
                                    data-tip="Format document<br>(Ctrl + F)"
                                >
                                    {
                                        loadingActions.goFormattingCode ?
                                            <span className="loading loading-ring loading-xs"></span>
                                            :
                                            <IconBraces size={16} />
                                    }
                                </button>
                            </>}
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setExpandEditor(current => !current);
                                }}
                                className="btn btn-sm btn-ghost"
                            >
                                <IconArrowsMaximize size={16} />
                            </button>
                        </div>
                    </div>
                    <Tabs onClick={(e) => {
                        e.preventDefault();
                    }} tabs={scriptTabsGo} activeTab={goFileDisplay} onTabChange={onScriptTabChange} />
                    {goFileDisplay === "mod" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="go"
                        value={modScript}
                        onChange={handleModEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {goFileDisplay === "main" && (<Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="go"
                        value={mainScript}
                        onChange={setMainScript}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />)}
                    {renderErrors(error, 'mainScript')}
                    {renderErrors(error, 'modScript')}
                    {renderErrors(error, 'script')}
                </div>
            )}

            {(!selectedLanguage || selectedLanguage === 'javascript' || selectedLanguage === 'Javascript') && (
                <div className="form-control w-full">
                    {!expandEditor && (
                        <div className="flex justify-between items-center">
                            <label className="label">
                                <span className="label-text">Script</span>
                            </label>
                            <div>
                                {editMode && <button
                                    onClick={e => {
                                        e.preventDefault();
                                        formatDocument()
                                    }}
                                    className="btn btn-sm btn-ghost tooltip"
                                    data-tip="Format document<br>(Ctrl + F)"
                                >
                                    <IconBraces size={16} />
                                </button>}
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setExpandEditor(current => !current);
                                    }}
                                    className="btn btn-sm btn-ghost"
                                >
                                    <IconArrowsMaximize size={16} />
                                </button>
                            </div>
                        </div>
                    )}
                    <Editor
                        theme="vs-dark"
                        height={expandEditor ? "100vh" : "300px"}
                        options={{
                            fontSize: 15,
                            quickSuggestions: false,
                            scrollBeyondLastLine: false,
                            readOnly: !editMode,
                            minimap: { enabled: false }
                        }}
                        defaultLanguage="javascript"
                        value={script}
                        onChange={handleEditorChange}
                        beforeMount={handleEditorWillMount}
                        onMount={handleEditorDidMount}
                    />

                </div>
            )}
        </div>
    );
};

export default ScriptLanguageSelector;
